import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/',
        name: 'Admin',
        component: () => import("../layout/manage"),
        children: [
            {
                path: 'home',
                name: 'FrontHome',
                component: () => import("../views/home")
            },
            {
                path: 'user',
                name: 'user',
                component: () => import("../views/user")
            },
            {
                path: 'goods',
                name: 'goods',
                component: () => import("../views/goods")
            },
            {
                path: 'category',
                name: 'category',
                component: () => import("../views/category")
            },
            {
                path: 'tags',
                name: 'tags',
                component: () => import("../views/tags")
            },
            {
                path: 'order',
                name: 'order',
                component: () => import("../views/order")
            },
            {
                path: 'banner',
                name: 'Banner',
                component: () => import("../views/banner")
            },
            {
                path: 'address',
                name: 'Address',
                component: () => import("../views/address")
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/login")
    },
]


const createRouter = () => new VueRouter({
    mode: 'history',
    routes: routes
})

const router = createRouter()

let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}

export default router
